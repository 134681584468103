

@font-face {
  font-family: 'Graphik-regular';
  src: url(../src/assets/fonts/GraphikFont/Graphik-Regular-Web.woff), url(../src/assets/fonts/GraphikFont/Graphik-Regular-Web.woff2);
}

@font-face {
  font-family: 'Graphik-medium';
  src: url(../src/assets/fonts/GraphikFont/Graphik-Medium-Web.woff), url(../src/assets/fonts/GraphikFont/Graphik-Medium-Web.woff2);
}

@font-face {
  font-family: 'Aktual Book';
  src: url(../src/assets/fonts/aktual/FTAktualTrial-Book.woff);
}

@font-face {
  font-family: 'Aktual Regular';
  src: url(../src/assets/fonts/aktual/FTAktualTrial-Regular.woff);
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: "Aktual Book";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


img{
  max-width: 100%;
  display: block;
}

