.mainHome {
  background-image: url(../../../src/assets/images/visio_Wide_2.avif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 88% 100%;
  @media (max-width: 1250px) {
    background-position: 70% 100%;
  }
}

.banner {
  position: relative;
  height: 65vh;
  &Logo {
    position: absolute;
    top: 0;
    left: 0;
    & + img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 89% 100%;
    }
  }
  &Content {
    padding: 40px;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: rgb(173 166 152 / 40%);
    backdrop-filter: blur(15px);
    h1 {
      color: #423728;
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      br {
        display: none;
      }
    }
  }
}

.future {
  width: 100%;
  height: 35vh;
  padding: 0 40px 0;
  // background-color: #7F715A;
  display: flex;
  align-items: center;
  // background-color: #7F715A;
  background-color: #625237;
  // backdrop-filter: blur(20px);
  &Content {
    max-width: 460px;
    margin: 0 auto;
    h2 {
      color: #dfdbd5;
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 36px;
    }
    a {
      color: #bbb3a5;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 14px 40px;
      border-radius: 60px;
      border: 1px solid #bbb3a5;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(10px);
      display: block;
      text-align: center;
      text-decoration: none;
      width: fit-content;
      margin: 0 auto;
      transition: all 0.1s ease-in-out;
      font-family: "Aktual Regular";
      &:hover {
        background-color: #bbb3a5;
        color: #423728;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    height: 75vh;
    &Logo {
      top: -16px;
      left: -24px;
    }
    &Content {
      h1 {
        letter-spacing: -0.5px;
        line-height: 100%;
        color: #ebe9e5;
        br {
          display: inline-block;
        }
      }
    }
  }
  .future {
    padding: 0;
    height: 25vh;
    &Content {
      max-width: 286px;
      h2 {
        font-size: 20px;
        line-height: 110%;
        padding-bottom: 26px;
      }
      a {
        font-size: 16px;
        letter-spacing: 0.28px;
        padding: 10px;
      }
    }
  }
}
