.panoview {
  position: relative;
  width: 100%;
}

.modal {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #7f715a;
  &.none{
    display: none;
  }
  &Content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .form {
    max-width: 498px;
    padding: 56px 32px;
    width: 100%;
    text-align: center;
    &.success {
      input {
        background-color: #89c36e;
        border: 1px solid #89c36e;
      }
      button {
        background-color: #89c36e;
      }
    }
    &.error {
      input {
        outline: 4px solid #ff570f;
        border: 1px solid transparent;
        &:focus-visible {
          outline: 4px solid #ff570f;
        }
      }
      button {
        background-color: #ff570f;
      }
    }
    h3 {
      color: #cdbea8;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 20px;
    }
    input {
      width: 100%;
      display: block;
      padding: 20px 40px;
      border-radius: 60px;
      border: 1px solid #e6e4e0;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(10px);
      margin-bottom: 24px;
      color: #e6e4e0;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      outline: none;
      background-color: #7f715a;
      transition: all 0.2s ease-in-out;
      outline: 4px solid transparent;
      &::placeholder {
        color: #e6e4e060;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
      }
      &:focus-visible {
        outline: none;
      }
    }
    button {
      border: none;
      outline: none;
      padding: 12px 12px;
      color: #47310d;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      background-color: #bf7d10;
      border-radius: 999px;
      max-width: 200px;
      width: 100%;
      cursor: pointer;
      margin: 0 auto;
      transition: all 0.2s ease-in-out;
      &:disabled {
        background-color: #b4a180;
      }
    }
  }
}
